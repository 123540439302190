import settingMenuIcon from '../../images/takeout/setting-menu-green.svg';
import takeoutIcon from '../../images/takeout/takeout-green.svg';

export interface FunctionInfo {
  title: string;
  available: boolean;
  path: string;
  image: string;
}

const functions: FunctionInfo[] = [
  {
    title: '電子メニュー設定',
    available: process.env.AVAILABILITY_ADMIN_SETTINGS === 'true',
    path: '/admin/settings/menu',
    image: settingMenuIcon,
  },
  {
    title: 'テイクアウト予約管理',
    available: process.env.AVAILABILITY_ADMIN_TAKEOUT === 'true',
    path: '/admin/takeout/calendar',
    image: takeoutIcon,
  },
];

export const availableFunctions = functions.filter((e) => e.available);
